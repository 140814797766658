
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import DropdownFilterStatuses from '@/components/shared/filters/dropdown/DropdownFilterStatuses.vue'
import DropdownFilterTags from '@/components/shared/filters/dropdown/DropdownFilterTags.vue'
import DropdownFilterAssignee from '@/components/shared/filters/dropdown/DropdownFilterAssignee.vue'
import DropdownFilterRadio from '@/components/shared/filters/radio/DropdownFilterRadio.vue'
import DropdownFilterChannels from '@/components/shared/filters/dropdown/DropdownFilterChannels.vue'
import { assigneeOptions, chatStatusOptions, chatTagsFilterOptions, chatChannelsFilterOptions } from '@/definitions/_general/_data/optionsList'
import { dateCreated } from '@/definitions/shared/filters/filtersLib/data'

export default defineComponent({
  components: {
    TmButton,
    TmModal,
    DropdownFilterStatuses,
    DropdownFilterTags,
    DropdownFilterAssignee,
    DropdownFilterRadio,
    DropdownFilterChannels,
  },
  setup() {
    const chatStatusValue = ref()
    const assigneeValue = ref()
    const chatTagsValue = ref()
    const chatChannelsValue = ref()
    const lastUpdatedValue = ref()
    const createdValue = ref()
    const updatedByValue = ref()

    const resetAll = () => {
      chatStatusValue.value = ''
      assigneeValue.value = []
      chatTagsValue.value = ''
      chatChannelsValue.value = ''
      lastUpdatedValue.value = ''
      createdValue.value = []
      updatedByValue.value = ''
    }

    return {
      chatStatusValue,
      chatStatusOptions,
      assigneeValue,
      assigneeOptions,
      chatTagsValue,
      chatTagsFilterOptions,
      chatChannelsValue,
      chatChannelsFilterOptions,
      lastUpdatedValue,
      createdValue,
      dateCreated,
      updatedByValue,
      resetAll,
    }
  },
})
